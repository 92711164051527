import ApiService from '@api/api.service';
import { ProductClient } from '@api/product/product.client';
import { IChannelComponentItemDto, ItemTypeEnum } from '@models/channel-component/responseDto/channel-component.dto';
import ComponentProduct from '@models/product/component-product';
import { IComponentProductDto } from '@models/product/responseDto/component-product.dto';
import { CategoryQuery } from '@queries/category-detail';
import RoutesEnum from '@routes';
import { Url } from '@type/common';
import { ProductId } from '@type/models';
import DomainUtils from '@utils/domain.utils';
import EnvironmentUtils from '@utils/environment.utils';

type DisplayProducts = {
    status: 'loaded';
    products: ComponentProduct[];
};

type PendingDisplayProducts = {
    status: 'pending';
    products: ProductId[];
};

type NoDisplayProducts = {
    status: 'none';
};

type ErroredDisplayProducts = {
    status: 'error';
};

type DisplayProductStatus = DisplayProducts | PendingDisplayProducts | NoDisplayProducts | ErroredDisplayProducts;

class ChannelComponentItem {
    readonly id: string;
    private _componentType?: string;
    private _categoryName?: string;
    private _name?: string | null;
    private _index?: number;
    private _categoryIndex?: number;
    private _categoryId?: string;
    private _image?: string;
    private _title?: string;
    private _subTitle?: string;
    private _isForPage?: boolean;
    private _pageUrl: string | null;
    private _imageOverlayText?: string;

    private _displayProductStatus: DisplayProductStatus;

    private _itemType?: ItemTypeEnum;
    private _productRows: number;
    private _productCols: number;
    private _channelBrandId?: number;
    private _channelBrandName?: string;
    private _channelBrandDisplayImageUrl?: string;
    private _channelBrandIsActive?: boolean;
    private _brandId?: string;
    private _isUsingCustomImage?: boolean;
    private _offlineStoreId?: string;
    readonly externalPageUrl: string | null;
    readonly isUsingExternalPageUrl: boolean;

    private _componentName?: string;

    private readonly _NORMAL_MALL_BANNER = '단건몰 연동 배너';

    constructor(dto: IChannelComponentItemDto, itemType: ItemTypeEnum | undefined, productRows: number, productCols: number, componentName?: string) {
        this.id = dto.id;
        this._componentType = dto.componentType;
        this._categoryName = dto.categoryName;
        this._name = dto.name;
        this._index = dto.index;
        this._categoryIndex = dto.categoryIndex;
        this._categoryId = dto.categoryId;
        this._image = dto.image;
        this._isForPage = dto.isForPage;
        this._pageUrl = dto.pageUrl;
        this._title = dto.title;
        this._subTitle = dto.subTitle;
        this._imageOverlayText = dto.imageOverlayText;
        this._channelBrandId = dto.channelBrandId;
        this._channelBrandName = dto.channelBrandName;
        this._channelBrandDisplayImageUrl = dto.channelBrandDisplayImageUrl;
        this._brandId = dto.brandId;
        this._channelBrandIsActive = dto.channelBrandIsActive;
        this._isUsingCustomImage = dto.isUsingCustomImage;
        this._offlineStoreId = dto.offlineStoreId;
        this._componentName = componentName;
        this.externalPageUrl = dto.externalPageUrl;
        this.isUsingExternalPageUrl = dto.isUsingExternalPageUrl && !this.externalPageUrl?.includes(EnvironmentUtils.publicUrl);

        if (dto.mainDisplayProductList) {
            if (typeof dto.mainDisplayProductList[0] !== 'string') {
                this._displayProductStatus = {
                    status: 'loaded',
                    products: dto.mainDisplayProductList.map((product) => new ComponentProduct(product as IComponentProductDto)),
                };
            } else {
                this._displayProductStatus = {
                    status: 'pending',
                    products: dto.mainDisplayProductList as string[],
                };
            }
        } else {
            this._displayProductStatus = {
                status: 'none',
            };
        }

        this._itemType = itemType;
        this._productRows = productRows;
        this._productCols = productCols;
    }

    get categoryName(): string | undefined {
        return this._categoryName;
    }

    get name(): string | undefined {
        return this._name || undefined;
    }

    get isIcon(): boolean {
        return !this._image?.includes('component-img');
    }

    get image(): string | undefined {
        return this._image;
    }

    get title(): string | undefined {
        return this._title;
    }

    get subTitle(): string | undefined {
        return this._subTitle;
    }

    get pageUrl(): string | undefined {
        if (this._componentName === this._NORMAL_MALL_BANNER) {
            if (DomainUtils.isNhPay) {
                return `https://${EnvironmentUtils.NHPAY_NORMAL_MALL_URL}/?external_user_id=${ApiService.getAccessToken()}`;
            }

            if (DomainUtils.isNhPayNormal) {
                return `https://${EnvironmentUtils.NHPAY_SUBSCRIPTION_MALL_URL}/?external_user_id=${ApiService.getAccessToken()}`;
            }
        }

        return this._pageUrl || undefined;
    }

    get categoryId(): string | undefined {
        return this._categoryId;
    }

    get pendingProducts(): boolean {
        return this._displayProductStatus.status === 'pending';
    }

    get mainDisplayProductList(): ComponentProduct[] {
        return this._displayProductStatus.status === 'loaded' ? this._displayProductStatus.products : [];
    }

    get imageOverlayText(): string {
        return this._imageOverlayText || '';
    }

    /**This product list takes into account the row and column count */
    get paginatedProductList(): ComponentProduct[][] {
        const productsPerPage = this._productRows * this._productCols;
        return this.mainDisplayProductList.reduce<ComponentProduct[][]>((accum, _, i, products) => {
            if (i % productsPerPage === 0) {
                accum.push(products.slice(i, i + productsPerPage));
            }
            return accum;
        }, []);
    }

    get productCols(): number {
        return this._productCols;
    }

    get productRows(): number {
        return this._productRows;
    }

    get displayName(): string {
        let name: string;
        switch (this._itemType) {
            case ItemTypeEnum.Self:
                name = this._name || '';
                break;
            case ItemTypeEnum.Category:
                name = this._categoryName || '';
                break;
            default:
                name = '';
        }

        return name;
    }

    get legacyLink(): Url {
        if (this._itemType === ItemTypeEnum.Self) {
            return this._pageUrl || '';
        } else {
            return {
                pathname: RoutesEnum.CategoryDetail,
                query: {
                    [CategoryQuery.CategoryId]: this._categoryId || '',
                    [CategoryQuery.RankPage]: true,
                },
            };
        }
    }

    get path(): Url {
        if (this._pageUrl) {
            return this._pageUrl;
        }

        if (this._categoryId) {
            return { pathname: RoutesEnum.CategoryDetail, query: { categoryId: this._categoryId } };
        }

        return '';
    }

    get channelBrandId(): number | undefined {
        return this._channelBrandId;
    }

    get channelBrandName(): string | undefined {
        return this._channelBrandName;
    }

    get channelBrandDisplayImageUrl(): string | undefined {
        return this._channelBrandDisplayImageUrl;
    }

    get channelBrandIsActive(): boolean | undefined {
        return this._channelBrandIsActive;
    }

    get brandId(): string | undefined {
        return this._brandId;
    }

    get brandImageUrl(): string | undefined {
        return this._isUsingCustomImage ? this._channelBrandDisplayImageUrl : this._image;
    }

    get offlineStoreId(): string | undefined {
        return this._offlineStoreId;
    }

    get index(): number | undefined {
        return this._index;
    }

    /**
     * If the main display products are yet to be loaded, then this will load them.
     */
    async updatePendingProducts(displayOutOfStock?: boolean): Promise<void> {
        try {
            if (this._displayProductStatus.status === 'pending') {
                const paginatedProducts = await ProductClient.postComponentProducts({ productIds: this._displayProductStatus.products, displayOutOfStock });
                this._displayProductStatus = {
                    status: 'loaded',
                    products: paginatedProducts.items.map((product) => new ComponentProduct(product)),
                };
            }
        } catch (err) {
            this._displayProductStatus = {
                status: 'error',
            };
        }
    }
}

export default ChannelComponentItem;
