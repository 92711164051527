import ChannelComponentItem from '@models/channel-component/channel-component-item';
import {
    ComponentDisplayTypeEnum,
    IChannelComponentDto,
    IChannelComponentItemDto,
    IChannelComponentMetadataDto,
    ItemTypeEnum,
} from '@models/channel-component/responseDto/channel-component.dto';
import { ImageRatio } from '@type/models';
import ConversionUtils from '@utils/conversion.utils';

/**
 * Channel components intended for the home page
 * @link https://harmony-channel-dev.travelflan.com/docs#/end-user-channel/get_channel_components_api_v1_external_e_channels__channel_id__components_get
 */
class ChannelComponent {
    readonly id: string;
    private _componentType: string;
    private _componentName?: string;
    private _itemType?: ItemTypeEnum;
    private _displayType?: string;
    private _title?: string;
    private _subTitle?: string;
    private _imageRatio?: ImageRatio;
    private _itemMinCount?: number;
    private _itemMaxCount?: number;
    private _productMaxCount?: number;
    private _productRows: number;
    private _productCols: number;
    private _startDt?: string;
    private _endDt?: string;
    private _items: ChannelComponentItem[];
    private _metadata?: IChannelComponentMetadataDto;
    private _brandRows?: number;
    private _brandCols?: number;

    constructor(componentDto: IChannelComponentDto, componentItemDtos?: IChannelComponentItemDto[]) {
        this.id = componentDto.id;
        this._componentType = componentDto.componentType;
        this._componentName = componentDto.componentName;
        this._itemType = componentDto.itemType;
        this._displayType = componentDto.displayType;
        this._title = componentDto.title;
        this._subTitle = componentDto.subTitle;
        this._imageRatio = componentDto.imageRatio;
        this._itemMinCount = componentDto.itemMinCount;
        this._itemMaxCount = componentDto.itemMaxCount;
        this._productMaxCount = componentDto.productMaxCount;
        this._productRows = componentDto.productRows || 1;
        this._productCols = componentDto.productCols || 3;
        this._startDt = componentDto.startDt;
        this._endDt = componentDto.endDt;
        this._items = (componentItemDtos || componentDto.items)?.map(
            (item) => new ChannelComponentItem(item, this._itemType, this._productRows, this._productCols, this._componentName)
        );
        this._metadata = componentDto.metadata;
        this._brandRows = componentDto.brandRows;
        this._brandCols = componentDto.brandCols;
    }

    get componentType(): string {
        return this._componentType;
    }

    get displayType(): ComponentDisplayTypeEnum | string | undefined {
        return this._displayType;
    }

    get title(): string | undefined {
        return this._title;
    }

    get subtitle(): string | undefined {
        return this._subTitle;
    }

    get items(): ChannelComponentItem[] {
        return this._items;
    }

    get firstItem(): ChannelComponentItem | undefined {
        return this._items[0];
    }

    get hasImageRatio(): boolean {
        return !!this._imageRatio;
    }

    get imageRatio(): number {
        if (!this._imageRatio) {
            return 0;
        }

        const [w, h] = this._imageRatio;
        return h / w;
    }

    /**
     * Multiply this by the desired width of the image to get the ratio adjusted height
     */
    get imageHeightFactor(): number {
        return ConversionUtils.imageRatioToHeightFactor(this._imageRatio);
    }

    get productCols(): number {
        return this._productCols || 1;
    }

    get itemType(): ItemTypeEnum | undefined {
        return this._itemType;
    }

    get productMaxCount(): number | undefined {
        return this._productMaxCount;
    }

    get endDate(): Date | undefined {
        return this._endDt ? new Date(this._endDt) : undefined;
    }

    get timeRemaining(): number | undefined {
        if (!this._endDt) return undefined;
        const remainingTime = Math.floor((new Date(this._endDt).getTime() - new Date().getTime()) / 1000);
        return remainingTime < 0 ? 0 : remainingTime;
    }

    get isBeforeStart(): boolean {
        if (!this._startDt) return false;
        return new Date().getTime() < new Date(this._startDt).getTime();
    }

    get metadata(): IChannelComponentMetadataDto | undefined {
        return this._metadata;
    }

    get brandRows(): number {
        return this._brandRows || 1;
    }

    get brandCols(): number {
        return this._brandCols || 1;
    }

    get isNoItemComponent(): boolean {
        const ComponentsWithoutComponentItem = ['divider', 'point', 'review'];
        return ComponentsWithoutComponentItem.includes(this._componentType);
    }

    get brandItems(): ChannelComponentItem[] {
        const items = this._items.slice(1);
        return items.filter((item) => !!item.channelBrandIsActive);
    }

    get offlineStoreIds(): string[] | undefined {
        if (!!this._items.length) return undefined;
        return this._items.sort((a, b) => (a.index || 0) - (b.index || 0)).map((item) => item.offlineStoreId || '');
    }

    /**
     *
     * @param factor the number of items you want per group of items
     * @param max the max number of items to return
     * @returns 2D array of component items
     */
    itemGroups(factor: number, max?: number): ChannelComponentItem[][] {
        const itemGroups: ChannelComponentItem[][] = [];
        let currentGroup: ChannelComponentItem[] = [];

        const items = max ? this._items.slice(0, max) : this._items;
        for (const item of items) {
            currentGroup.push(item);

            if (currentGroup.length === factor) {
                itemGroups.push(currentGroup);
                currentGroup = [];
            }
        }

        if (currentGroup.length > 0) {
            itemGroups.push(currentGroup);
        }

        return itemGroups;
    }
}

export default ChannelComponent;
