import { IComponentProductDto } from '@models/product/responseDto/component-product.dto';
import { ImageRatio, ProductId } from '@type/models';

export enum ChannelComponentTypeEnum {
    Banner = 'banner',
    ProductList = 'product_list',
    TimeSale = 'time_sale',
    ImageList = 'image_list',
    SubMenu = 'sub_menu',
    IconMenu = 'icon_menu',
    Magazine = 'magazine',
    Theme = 'theme',
    RankList = 'rank_list',
    ImageMenu = 'image_menu',
    Divider = 'divider',
    Points = 'point',
    Brand = 'brand',
    Review = 'review',
    OfflineStore = 'offline_store',
}

export enum ComponentDisplayTypeEnum {
    Rol = 'rol',
    Fix = 'fix',
    Scroll = 'scroll',
    List = 'list',
    Button = 'button',
    Swipe = 'swipe',
    Accordion = 'accordion',
    Translucent = 'translucent',
    Custom = 'custom',
}

export enum ItemTypeEnum {
    Self = 'self',
    Category = 'category',
}

export interface IChannelComponentItemDto {
    id: string;
    componentType?: ChannelComponentTypeEnum | string;
    categoryName?: string;
    name?: string | null;
    title?: string;
    subTitle?: string;
    index?: number;
    categoryIndex?: number;
    categoryId?: string;
    image?: string;
    isForPage?: boolean;
    pageUrl: string | null;
    mainDisplayProductList?: ProductId[] | IComponentProductDto[];
    imageOverlayText?: string;
    channelBrandId?: number;
    channelBrandName?: string;
    channelBrandDisplayImageUrl?: string;
    channelBrandIsActive?: boolean;
    brandId?: string;
    isUsingCustomImage?: boolean;
    offlineStoreId?: string;
    externalPageUrl: string | null;
    isUsingExternalPageUrl: boolean;
}
export interface IChannelComponentDto {
    id: string;
    componentType: ChannelComponentTypeEnum | string;
    componentName?: string;
    itemType?: ItemTypeEnum;
    displayType?: ComponentDisplayTypeEnum | string;
    title?: string;
    subTitle?: string;
    imageRatio?: ImageRatio;
    itemMinCount?: number;
    itemMaxCount?: number;
    productMaxCount?: number;
    productRows?: number | null;
    productCols?: number | null;
    startDt?: string;
    endDt?: string;
    items: IChannelComponentItemDto[];
    metadata?: IChannelComponentMetadataDto;
    brandRows?: number;
    brandCols?: number;
}

export interface IChannelComponentMetadataDto {
    color: string;
    opacity: number;
    height: number;
}
