import { ChannelClient } from '@api/channel/channel.client';
import { css } from '@emotion/react';
import { useDesignComponents } from '@hooks/use-design-components';
import { useGATracking } from '@hooks/use-ga-tracking';
import { useHarmony } from '@hooks/use-harmony';
import { useWindowSize } from '@hooks/use-window-size';
import LegacyPagination from '@legacy/designs/legacy-pagination';
import { ActivityTypeEnum, LinkedItemTypeEnum } from '@models/activity/requestDto/post-activity-request.dto';
import ChannelComponent from '@models/channel-component';
import { ComponentDisplayTypeEnum } from '@models/channel-component/responseDto/channel-component.dto';
import safeWindow from '@services/safe-window.service';
import { Design } from '@type/harmony-config';
import EnvironmentUtils from '@utils/environment.utils';
import ThemeUtils from '@utils/theme.utils';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import CustomImage from 'src/legacy/components/common/custom-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import BannerA from './designA/banner';
import BannerContainerA from './designA/banner-container';
import FixedBannerA from './designA/fixed-banner';
import BannerB from './designB/banner';
import BannerContainerB from './designB/banner-container';
import FixedBannerB from './designB/fixed-banner';
import BannerC from './designC/banner';
import BannerContainerC from './designC/banner-container';
import FixedBannerC from './designC/fixed-banner';

interface BannerProps {
    channelComponent: ChannelComponent;
}

const Banner: React.FC<BannerProps> = ({ channelComponent }) => {
    const { logEvent } = useGATracking();
    const BannerContainer = useDesignComponents<typeof BannerContainerA | typeof BannerContainerB | typeof BannerContainerC>({
        [Design.DesignA]: BannerContainerA,
        [Design.DesignB]: BannerContainerB,
        [Design.DesignC]: BannerContainerC,
    });
    const Banner = useDesignComponents<typeof BannerA | typeof BannerB | typeof BannerC>({
        [Design.DesignA]: BannerA,
        [Design.DesignB]: BannerB,
        [Design.DesignC]: BannerC,
    });
    const FixedBanner = useDesignComponents<typeof FixedBannerA | typeof FixedBannerB | typeof FixedBannerC>({
        [Design.DesignA]: FixedBannerA,
        [Design.DesignB]: FixedBannerB,
        [Design.DesignC]: FixedBannerC,
    });
    const [index, setIndex] = useState<number>(0);
    const { theme } = useHarmony();
    const firstItem = channelComponent.items[0];
    const [width] = useWindowSize({ maxWidth: true });

    const bannerWidth = useDesignComponents<number>({
        [Design.DesignA]: width,
        [Design.DesignB]: width - 32,
        [Design.DesignC]: width,
    });

    const router = useRouter();

    const handlePagePath = (pagePath: string, isExternal: boolean, componentItemId: string) => {
        if ([EnvironmentUtils.NHPAY_NORMAL_MALL_URL, EnvironmentUtils.NHPAY_SUBSCRIPTION_MALL_URL].some((url) => pagePath.includes(url as string))) {
            location.href = 'appcall://calllink?url=' + pagePath;
            return;
        }

        if (isExternal) {
            ChannelClient.postActivity({
                activityType: ActivityTypeEnum.OpenUrl,
                linkedItemType: LinkedItemTypeEnum.Component,
                linkedUrl: pagePath,
                componentId: channelComponent.id,
                componentItemId: componentItemId,
            });
            safeWindow?.open(pagePath, '_blank');
            return;
        }

        router.push(pagePath);
    };

    useEffect(() => {
        channelComponent.items.forEach((item) => {
            logEvent('view_promotion', {
                promotion_name: item.pageUrl,
                items: [],
            });
        });
    }, []);

    return (
        <BannerContainer theme={theme}>
            {channelComponent.displayType === ComponentDisplayTypeEnum.Rol && (
                <Banner>
                    <Swiper
                        loop={true}
                        autoplay={{ delay: 5000, disableOnInteraction: false }}
                        onSlideChange={(swiper) => {
                            setIndex(swiper.realIndex);
                        }}
                        css={css`
                            ${channelComponent.hasImageRatio ? `height: calc(min(100vw, ${bannerWidth}px) * ${channelComponent.imageHeightFactor})` : ''};
                            z-index: ${ThemeUtils.zIndex.below};
                        `}
                    >
                        {channelComponent.items.map((item, i) => (
                            <SwiperSlide key={i}>
                                <div
                                    css={css`
                                        position: relative;
                                        width: 100%;
                                        height: 100%;
                                        cursor: ${item.externalPageUrl || item.pageUrl ? 'pointer' : 'default'};
                                    `}
                                    onClick={() => {
                                        if (item.pageUrl) {
                                            logEvent('select_promotion', {
                                                promotion_name: item.pageUrl,
                                            });
                                        }

                                        const url = item.externalPageUrl || item.pageUrl;
                                        if (!!url) {
                                            handlePagePath(url, item.isUsingExternalPageUrl, item.id);
                                        }
                                    }}
                                >
                                    {item.image && <CustomImage unoptimized={true} src={item.image} alt={item.name || ''} layout="fill" objectFit="cover" />}
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <LegacyPagination index={index} total={channelComponent.items.length} />
                </Banner>
            )}
            {channelComponent.displayType === ComponentDisplayTypeEnum.Fix && firstItem && firstItem.image && (
                <FixedBanner
                    className={!firstItem.pageUrl ? 'disabled' : ''}
                    onClick={() => {
                        if (firstItem.pageUrl) {
                            logEvent('select_promotion', {
                                promotion_name: firstItem.pageUrl,
                            });
                        }

                        const url = firstItem.isUsingExternalPageUrl ? firstItem.externalPageUrl : firstItem.pageUrl;
                        if (!!url) {
                            handlePagePath(url, firstItem.isUsingExternalPageUrl, firstItem.id);
                        }
                    }}
                    width={`${bannerWidth}px`}
                    height={`${channelComponent.imageHeightFactor * bannerWidth}px`}
                >
                    <CustomImage src={firstItem.image} alt={firstItem.name} layout="fill" objectFit="cover" />
                </FixedBanner>
            )}
        </BannerContainer>
    );
};

export default Banner;
